<template>
  <div class="forgotPasswordConstainer">
    <b-form @submit="requestNewPassword" class="bg-white text-left resetForm">

      <b-alert show variant="danger" v-if="!this.passwordRequestOk">
        <strong>{{ $t(this.translationPath + 'errors.email_does_not_exist')}}</strong><br/>
        {{ invalidRequestEmailMessage }}
      </b-alert>

      <template v-if="!isInProcess">
        <b-form-group
          class="emailInputGroup mb-4"
          :label="$t('login.label.email')"
          label-for="emailInput"
          :invalid-feedback="invalidEmailFeedback"
          :state="!$v.email.$error">

          <b-form-input
            class="emailInput"
            type="email"
            v-model="email"
            :placeholder="$t('login.placeholder.email')"
            :state="$v.email.$dirty ? !$v.email.$error : null"
            @input="$v.email.$touch()"
          >
          </b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" class="w-100 text-uppercase">{{ $t('login.label.request_password') }}</b-button>
      </template>
      <template v-else>
        <loader loading-text="" />
      </template>
    </b-form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import axios from 'axios'
import Loader from '@/components/common/Loader'

const validPasswordResetRequest = getter => function (value) {
  return this.passwordRequestOk
}

export default {
  name: 'ForgotPassword',
  components: { Loader },
  data () {
    return {
      email: null,
      password: null,
      passwordRequestOk: true,
      invalidRequestEmailMessage: null,
      translationPath: 'login.',
      isInProcess: false
    }
  },
  validations: {
    email: {
      required,
      email,
      forgotPasswordRequestOk: validPasswordResetRequest()
    },
    form: ['email']
  },
  methods: {
    ...mapActions('user', ['login']),

    async requestNewPassword (event) {
      event.preventDefault()
      this.$v.form.$touch()
      this.passwordRequestOk = true

      if (this.$v.email.email) {
        this.isInProcess = true
        axios.post(`${process.env.VUE_APP_ROOT_API}/user/password/reset`, { email: this.email })
          .then(response => {
            this.$emit('success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.invalidRequestEmailMessage = this.$i18n.t(this.translationPath + 'errors.email_does_not_exist_no_reset_executed')
            } else {
              this.invalidRequestEmailMessage = this.$i18n.t(this.translationPath + 'errors.unknown_error', { code: error.response.status, message: error.response.data.message })
            }
            this.passwordRequestOk = false
          })
          .then(() => {
            this.isInProcess = false
          })
      }
    }
  },
  computed: {
    invalidEmailFeedback () {
      if (this.$v.email.required === false) { return this.$i18n.t(this.translationPath + 'errors.require_email') }
      if (this.$v.email.email === false) { return this.$i18n.t(this.translationPath + 'errors.require_valid_email') }
      if (this.$v.email.forgotPasswordRequestOk === false) { return this.$i18n.t(this.translationPath + 'errors.invalid_credentials_email') }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./../style.scss";
  .resetForm{
    border: 3px solid #ececec;
    border-radius: 30px;
  }
</style>
